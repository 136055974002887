function check() {
    console.info('scripts all green');
}

check()

window.onload = function () {

    /* swiper slider */
    const docs = new Swiper('.docs__swiper', {
        // Optional parameters
        direction: 'horizontal',
        loop: true,
        slidesPerView: 1,

        // If we need pagination

        pagination: {
            el: '.docs-pagination',
            bulletClass: 'docs__bullet',
            bulletActiveClass: 'docs__active',
            type: 'bullets',
            clickable: true,
        },

    });

    /* реализация плавной прокрутки */
    const menuLinks = document.querySelectorAll('a[data-goto]');
    const menuList = document.querySelector('.nav__list');


    if (menuLinks.length > 0) {
        menuLinks.forEach(menuLink => {
           menuLink.addEventListener('click', onMenuLinkClick)
        });
    }


    function onMenuLinkClick(e) {
        const menuLink = e.target;
        if (menuLink.dataset.goto && document.querySelector(menuLink.dataset.goto)) {
            const gotoBlock = document.querySelector(menuLink.dataset.goto);
            const gotoBlockValue = gotoBlock.getBoundingClientRect().top + pageYOffset - document.querySelector('nav').offsetHeight;

            if (iconMenu.classList.contains('_active')) {
                document.body.classList.remove('_lock');
                iconMenu.classList.remove('_active');
                menuList.classList.remove('_active');
            }

            window.scrollTo({
                top: gotoBlockValue,
                behavior: 'smooth',
            });
            e.preventDefault();
        }
    }

    /* прокрутка от кнопки */

    const headerButton = document.querySelector('.header__button');
    function onButtonClick(e) {
        const gotoBlock = document.querySelector('.problems');
        const gotoBlockValue = gotoBlock.getBoundingClientRect().top + pageYOffset - document.querySelector('nav').offsetHeight;

        window.scrollTo({
            top: gotoBlockValue,
            behavior: 'smooth',
        });
        e.preventDefault();
    }

    headerButton.addEventListener('click', onButtonClick);



    /* меню бургер */
    const iconMenu = document.querySelector('.menu__icon');
    if (iconMenu) {
        const menuList = document.querySelector('.nav__list');
        iconMenu.addEventListener('click', function (e) {
            document.body.classList.toggle('_lock');
            iconMenu.classList.toggle('_active');
            menuList.classList.toggle('_active');
        })
    }


} //end window.onload